const getModulesWithBgChange = (module, moduleDataArray) => {
	let moduleBgData = {};
	const moduleTypeHandle = module.typeHandle;

	moduleDataArray.forEach((item) => {
		if (item.__typename === `Craft_${moduleTypeHandle}_background_BlockType`) {
			const bgColor = item?.zmianaBackgroundu || '';
			const multiplier = item?.liczba || '';
			if (bgColor !== '') {
				const moduleId = module.remoteId + module.__typename;
				moduleBgData = {
					id: moduleId,
					bgColor,
					multiplier,
				};
			}
		}
	});

	return moduleBgData;
};

export default getModulesWithBgChange;
