/*eslint-disable*/
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo/seo';
import BackgroundChange from '../components/backgroundChange/BackgroundChange';
import isObjectEmpty from '../utils/isObjectEmpty';
import getModulesWithBgChange from '../utils/getModulesWithBgChange';
import { connect, useDispatch } from 'react-redux';
import { toggleIsIntroRendered } from '../state/app';
import SingleNewsExtendedContent from '../components/singleNews/singleNewsExtendedContent';
import getContentModuleWithBgChange from '../utils/getContentModuleWithBgChange';
import ModulesContent from '../components/singleNews/ModulesContent';

const StyledWrapper = styled.div`
	height: 100%;
	width: 100%;
`;

const DifferentPage = ({ data: { seoDifferentPages, differentPageData }, pageContext, isIntroRendered }) => {
	const differentPageContent = differentPageData?.nodes?.[0]?.trescPodstrony || [];
	const activeSite = pageContext?.site || '';
	const globalSeo = pageContext?.globals?.data?.globalSeo?.nodes?.[0] || [];
	const seoData = seoDifferentPages?.nodes?.[0] || [];
	const pageTitleForBreadCrumbs = differentPageData?.nodes?.[0]?.title || '';

	const dispatch = useDispatch();
	useEffect(() => {
		// eslint-disable-next-line radix
		if (!isIntroRendered) {
			dispatch(toggleIsIntroRendered(true));
		}
	}, []);

	const arrayForBgChanges = [];

	const addToArrayForBgChanges = (moduleToBgChange) => {
		if (!isObjectEmpty(moduleToBgChange)) {
			arrayForBgChanges.push(moduleToBgChange);
		}
	};

	const setBgChangeForModules = (item) => {
		const modulesData = item?.wybierzModul || [];

		modulesData.forEach((module) => {
			if (module.__typename === 'Craft_moduly_modulNaglowekTlo_Entry') {
				const moduleDataArray = module?.modulNaglowekTlo || [];
				const moduleToBgChange = getModulesWithBgChange(module, moduleDataArray);
				addToArrayForBgChanges(moduleToBgChange);
			} else if (module.__typename === 'Craft_moduly_modulNaglowekZdjecie_Entry') {
				const moduleDataArray = module?.modulNaglowekZdjecie || [];
				const moduleToBgChange = getModulesWithBgChange(module, moduleDataArray);
				addToArrayForBgChanges(moduleToBgChange);
			} else if (module.__typename === 'Craft_moduly_modulProduktTabelka_Entry') {
				const moduleDataArray = module?.modulProduktTabelka || [];
				const moduleToBgChange = getModulesWithBgChange(module, moduleDataArray);
				addToArrayForBgChanges(moduleToBgChange);
			} else if (module.__typename === 'Craft_moduly_modulOpis_Entry') {
				const moduleDataArray = module?.modulOpis || [];
				const moduleToBgChange = getModulesWithBgChange(module, moduleDataArray);
				addToArrayForBgChanges(moduleToBgChange);
			}
		});
	};

	if (differentPageContent) {
		differentPageContent.forEach((item) => {
			if (item.__typename === 'Craft_trescPodstrony_opis_BlockType') {
				const bgColor = item?.zmianaBackgroundu || [];
				if (bgColor !== 'unset') {
					addToArrayForBgChanges(getContentModuleWithBgChange(item));
				}
			} else if (item.__typename === 'Craft_trescPodstrony_video_BlockType') {
				const bgColor = item?.zmianaBackgroundu || [];
				if (bgColor !== 'unset') {
					addToArrayForBgChanges(getContentModuleWithBgChange(item));
				}
			} else if (item.__typename === 'Craft_trescPodstrony_zdjecieDuze_BlockType') {
				const bgColor = item?.zmianaBackgroundu || [];
				if (bgColor !== 'unset') {
					addToArrayForBgChanges(getContentModuleWithBgChange(item));
				}
			} else if (item.__typename === 'Craft_trescPodstrony_modul_BlockType') {
				setBgChangeForModules(item);
			}
		});
	}

	return (
		<>
			<StyledWrapper>
				<SEO globalSeo={globalSeo} seoData={seoData} />
				{arrayForBgChanges && <BackgroundChange arrayForBgChanges={arrayForBgChanges} />}
				<SingleNewsExtendedContent
					activeSite={activeSite}
					data={differentPageContent}
					productData=""
					productsPerCategoryData=""
					allProductPageSlug=""
					isDifferentPage={true}
					pageTitleForBreadCrumbs={pageTitleForBreadCrumbs}
				/>
			</StyledWrapper>
		</>
	);
};

export default connect(
	(state) => ({
		isIntroRendered: state.app.isIntroRendered,
	}),
	null,
)(DifferentPage);

export const query = graphql`
	query($remoteId: ID, $site: String, $firstOtherLangSite: String) {
		differentPageData: allCraftInnePodstronyInnePodstronyEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
			nodes {
				slug
				title
				remoteTypeName
				uri
				level
				remoteParent {
					title
					uri
				}
				remoteChildren {
					slug
					uri
				}
				id: remoteId
				trescPodstrony {
					__typename
					... on Craft_trescPodstrony_naglowek_BlockType {
						naglowek
					}
					... on Craft_trescPodstrony_opis_BlockType {
						opis
						id
						__typename
						zmianaBackgroundu
						liczba
					}
					... on Craft_trescPodstrony_zdjecie_BlockType {
						zdjecie {
							url
						}
						zdjecieMobile {
							url
						}
					}
					... on Craft_trescPodstrony_zdjecieDuze_BlockType {
						zdjecie {
							url
						}
						zdjecieMobile {
							url
						}
						id
						__typename
						zmianaBackgroundu
						liczba
					}
					... on Craft_trescPodstrony_video_BlockType {
						videoUrl
						videoPlik {
							url
						}
						id
						__typename
						zmianaBackgroundu
						liczba
					}
					... on Craft_trescPodstrony_modul_BlockType {
						wybierzModul {
							__typename
							typeHandle
							remoteId
							...modulNaglowekTlo
							...modulNaglowekZdjecie
							...modulProduktTabelka
							...modulNaglowekOpis
							...modulVideo
							...modulOpis
							...modulGaleria
							...modulNapiszDoNas
							...modulWszystkieProduktyZKategorii
						}
					}
				}
			}
		}
		globalCookies: allCraftCookiesGlobalSet(filter: { language: { eq: $site } }) {
			nodes {
				cookiesIkona {
					url
				}
				cookiesTekst
				cookiesNapisNaPrzycisku
			}
		}
		differentPagesOtherLang: allCraftInnePodstronyInnePodstronyEntry(filter: { language: { eq: $firstOtherLangSite } }) {
			nodes {
				slug
				id: remoteId
			}
		}
		seoDifferentPages: allCraftInnePodstronyInnePodstronyEntry(filter: { remoteId: { eq: $remoteId }, language: { eq: $site } }) {
			nodes {
				...seoInnePodstrony
			}
		}
	}
`;
