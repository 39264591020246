const getContentModuleWithBgChange = (item) => {
	let contentModuleBgData = {};

	contentModuleBgData = {
		id: item.id + item.__typename || '',
		bgColor: item?.zmianaBackgroundu || '',
		multiplier: item.liczba || '',
	};

	return contentModuleBgData;
};

export default getContentModuleWithBgChange;
